import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'
import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'

import { getSwatches, getArdadImages as getArdadImagesFunction } from '@/utils/product'
import { getBadgeText } from '@/utils/discount'
import { slots } from '@/config/ardad-configs'

import styles from './DetailProductTileSlider.module.scss'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { useEffect } from 'react'
import { affirmRefresh } from '@/utils/affirm'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const commonImageProps = {
    widths: { mobile: 348 },
    lazyLoadOffset: 200,
    imgixDomain: IMGIX_URL
}

const handleAffirmRefresh = (refresh) => {
    if (refresh) {
        affirmRefresh(100)
    }
}

const DetailProductTileSlider = ({
    products,
    title,
    sectionClassName,
    titleClassName,
    dataSelector,
    isCart = false,
    simpleSliderProps = {},
    customImageProps = {},
    showAffirm = false,
    hideBadge = false
}) => {
    const { getDiscounts } = useGetDiscounts()
    const { isTablet } = useBreakpoints('tablet')

    useEffect(() => {
        handleAffirmRefresh(showAffirm)
    })

    if (isEmpty(products)) {
        return null
    }

    const sliderContent = products.map(product => {
        const productData = {
            ...product,
            ...product.content
        }

        const { lowestPrice, highestPrice } = productData

        const baseProduct = {
            sku: productData.sku,
            parentSku: productData.parentSku || productData.sku,
            category: productData.category,
            quantity: 1,
            isPrimaryProduct: true
        }

        const { finalPrice: lowestPriceDiscount } = getDiscounts([{ ...baseProduct, price: lowestPrice }])
        const { finalPrice: highestPriceDiscount, discountAmount: highestDiscountAmount } = getDiscounts([{ ...baseProduct, price: highestPrice }])

        const priceRange = {
            fullPriceMin: lowestPrice,
            fullPriceMax: highestPrice,
            finalPriceMin: lowestPriceDiscount || 0,
            finalPriceMax: highestPriceDiscount || 0,
        }

        const title = productData.title
        const subtitle = productData.subtitle

        const swatches = getSwatches(productData.options, productData.defaultSwatch, productData.variants.length)
        const getImages = getArdadImagesFunction(productData, slots.productTile, { ...commonImageProps, ...customImageProps })
        const badgeText = getBadgeText(highestDiscountAmount, 'exact')

        return (
            <DetailProductTile
                key={productData.sku}
                containerClassName={styles.tile}
                title={{ text: title, href: productData.url, titleClassName: styles.title }}
                subtitle={{ text: subtitle, className: styles.subtitle }}
                imageHref={productData.url}
                priceRange={priceRange}
                swatches={swatches}
                getImages={getImages}
                imageBadgeLeft={badgeText && !hideBadge ? { text: badgeText, kind: 'promo', position: 'topLeft' } : undefined}
                affirm={showAffirm && !isTablet && { price: lowestPriceDiscount }}
            />
        )
    })

    const leftButtonClasses = classNames(styles.buttons, styles.leftButton)
    const rightButtonClasses = classNames(styles.buttons, styles.rightButton)

    const sliderProps = {
        isCart,
        sliderLabel: dataSelector,
        infinite: true,
        pageDotsAndNumbers: true,
        dotsClassName: styles.paginationDots,
        leftButtonClassName: leftButtonClasses,
        rightButtonClassName: rightButtonClasses,
        ...simpleSliderProps
    }

    const sectionClassNames = classNames('section', styles.section, sectionClassName)
    const titleClassNames = classNames('t-heading2 t-color', styles.sectionTitle, titleClassName)

    return (
        <section className={sectionClassNames} data-selector={dataSelector}>
            <div className="container">
                {title &&
                    <h2 className={titleClassNames}>
                        {title}
                    </h2>
                }
                <div className={styles.mobileSlider}>
                    <SimpleSlider
                        shownSize={1}
                        overflow={'hidden'}
                        onChange={() => handleAffirmRefresh(showAffirm)}
                        {...sliderProps}
                    >
                        {sliderContent}
                    </SimpleSlider>
                </div>
                <div className={styles.defaultSlider}>
                    <SimpleSlider
                        shownSize={3}
                        overflow={'hidden'}
                        onChange={() => handleAffirmRefresh(showAffirm)}
                        {...sliderProps}
                    >
                        {sliderContent}
                    </SimpleSlider>
                </div>
            </div>
        </section>
    )
}

DetailProductTileSlider.propTypes = {
    products: PropTypes.array.isRequired,
    title: PropTypes.string,
    sectionClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    dataSelector: PropTypes.string,
    isCart: PropTypes.bool,
    hasSwatches: PropTypes.bool,
    simpleSliderProps: PropTypes.object,
    showAffirm: PropTypes.bool,
    hideBadge: PropTypes.bool
}

export default DetailProductTileSlider
