import omit from 'lodash/omit'
import without from 'lodash/without'
import { generateAssetURLs } from '@saatva-bits/pattern-library.utils.product'

import { ardadConfigs, slots } from '@/config/ardad-configs'
import { handleWarningMessage } from './errorHandling'
import { getVariantAttributeValues } from './filters'

/**
 * Adds sortKeys and adds product and compare URIs
 *
 * @param {object} product
 * @returns
 */
export const formatProductDetails = (product) => {
    let details = {}
    product.details && product.details.forEach((detail, index) => {
        const key = detail.key
        const name = detail.name
        const features = omit(detail, ['key', 'name'])

        // This key (feature, firmness) has been added so we push into content array!
        if (details[key] && details[key].content && details[key].content.length > 0) {
            details[key].content.push(features)
        } else {
            details = {
                ...details,
                [key]: {
                    sortOrder: index,
                    key,
                    name,
                    content: [
                        features
                    ]
                }
            }
        }
    })
    const detailsList = Object.values(details).sort((a, b) => a.sortOrder - b.sortOrder)

    const shopCta = product.hasShopCta ? `/${product.category}/${product.sku}` : null
    const compareCta = product.hasCompareCta ? `/${product.category}/compare?sku=${product.sku}` : null

    return {
        details: detailsList,
        shopCta,
        compareCta
    }
}

/**
 * Build an object from an array of attributes for easy data access
 * @param {Object[]} attributes
 * @param {String} attributes.name
 * @param {String} attributes.value
 */
const buildAttributeObject = (attributes) => {
    if (!attributes) {
        return {}
    }

    const contentAttributeValues = {}

    attributes.forEach(attribute => {
        contentAttributeValues[attribute]

        if (contentAttributeValues[attribute.name]) {
            contentAttributeValues[attribute.name].push(attribute.value)
        } else {
            contentAttributeValues[attribute.name] = [attribute.value]
        }
    })

    return contentAttributeValues
}

/**
 * This is done to make variant filtering work, we add parent level attributes to each variant
 * to not change the implementation
 * @param {Object[]} variants
 * @param {Object} content
 * @param {Object[]} filterConfig
 */
const enrichVariantsWithFilterData = (variants, content, filterConfig) => {
    const contentAttributeValues = buildAttributeObject(content.attributes)

    for (let variant of variants) {
        for (const config of filterConfig) {
            // Only enrich parent filters
            if (config.target === 'parent') {
                for (const attributePath of config.attributePaths) {
                    // only enrich if its not overriding property from plytix
                    if (!variant[attributePath]) {
                        // first check the new attributes array froming from prismic
                        // then use the legacy attribute definition
                        if (contentAttributeValues[attributePath]) {
                            variant[attributePath] = contentAttributeValues[attributePath]
                        } else if (content[attributePath]) {
                            variant[attributePath] = content[attributePath].map(valueObj => valueObj.value)
                        }
                    }
                }
            }
        }
    }
    return variants
}

/**
 * Merges prismic product content with catalog product data
 *
 * @param {object} contentProduct
 * @param {object} catalogProduct
 * @param {object[]?} filterConfig
 * @returns
*/
export const formatProduct = (contentProduct, catalogProduct, filterConfig = []) => {
    return {
        ...catalogProduct,
        content: {
            ...contentProduct,
            ...catalogProduct.content,
            ...formatProductDetails(contentProduct)
        },
        variants: enrichVariantsWithFilterData(catalogProduct.variants, contentProduct, filterConfig)
    }
}

/**
 * Format the swatch name
 *
 * @param {string} swatch
 * @returns {string}
*/
export const formatSwatch = swatch => {
    // TODO: By default the first part is taken for the swatchModifier for the image asset path
    // But for this specific cases, we must use the second one, consider changing the asset path in assets repo?
    let specialCase = ['White / Sand', 'White / Indigo', 'White / Ash', 'White / Graphite', 'White / Taupe'].includes(swatch)
    let splitSwatch = swatch.toLowerCase().split(/ /g)

    let formattedSwatch = specialCase ? splitSwatch[2] : splitSwatch[0]

    switch (formattedSwatch) {
        case 'natural':
            formattedSwatch = 'linen'
            break
        default:
            break
    }

    return formattedSwatch
}

/**
 * Gets the initial default swatch
 *
 * @param {array} swatchOptions
 * @param {string} swatchDefault - Default from prismic
 * @param {number} swatchOffset - offset to randomize swatch to use, used if swatch default is not passed
 * @returns {string}
*/
const getDefaultSwatch = (swatchOptions, swatchDefault, swatchOffset) => {
    if (swatchDefault) return swatchDefault

    const index = swatchOptions && swatchOffset ? swatchOffset % swatchOptions.length : 0

    return swatchOptions[index]?.code
}

/**
 * @param {{
 *      attributePaths: string[];
 *      label: string;
 *      property: string;
 *      options: {
 *          value: string;
 *          label: string;
 *          matchingValues: string[]
 *      }[]
 *      }[]
 * } colorMaterialOptions - Filter config for colors that comes from catalog, we need the matching values array for the specific color we wanna map
 * @param {Record<'selectedColors' | 'selectedMaterials', Array<{ key: string, value: string, label: string }>>} filterValuesGroup - Grouping of selections by property
 * @param {object[]} matchingVariants - Variants that matched the filter selections
 * @returns {string}
 */
export const getSwatchFromSelections = (colorMaterialOptions, filterValuesGroup, matchingVariants) => {
    if (!matchingVariants) return null

    const { selectedColors, selectedMaterials } = filterValuesGroup

    let narrowedVariants = matchingVariants

    const narrowByFilter = (variants, matchingValues, filter) => {
        return variants.filter((variant) => {
            const attributeValues = getVariantAttributeValues(variant, filter)
            return attributeValues.some((value) => {
                return matchingValues.map(value => value.toLowerCase()).includes(value.toLowerCase())
            })
        })
    }

    if (selectedColors?.length) {
        const values = selectedColors.map(color => color.value)
        const matchingColorValues = []

        const colorFilter = colorMaterialOptions.find(
            option => option.property === 'colors'
        )

        // We iterate each selection, this one persists the order of the selections made by the user
        // this way we can ensure the next step where we filter the variants the order is correct.
        for (const value of values) {
            const matchingFilterOption = colorFilter.options.find((option) => option.value === value)
            if (matchingFilterOption) {
                matchingColorValues.push([...matchingFilterOption.matchingValues])
            }
        }

        // if we have more than one selection, means we should try to use the first selection always
        // but if that selection doesnt match any then we use the next one and so on to persist the current behavior
        for (const matchingColors of matchingColorValues) {
            let result = narrowByFilter(narrowedVariants, matchingColors, colorFilter)

            if (result.length) {
                narrowedVariants = result
                break
            }
        }
    }

    if (selectedMaterials?.length) {
        const values = selectedMaterials.map(material => material.value)
        const matchingMaterialValues = []

        const materialFilter = colorMaterialOptions.find(
            (option) => option.property === 'materials'
        )

        for (const value of values) {
            const matchingFilterOption = materialFilter.options.find((option) => option.value === value)
            if (matchingFilterOption) {
                matchingMaterialValues.push([...matchingFilterOption.matchingValues])
            }
        }

        for (const matchingMaterials of matchingMaterialValues) {
            let result = narrowByFilter(narrowedVariants, matchingMaterials, materialFilter)

            if (result.length) {
                narrowedVariants = result
                break
            }
        }
    }

    const swatch = narrowedVariants[0]?.fabric || narrowedVariants[0]?.color

    if (!swatch) return null

    return formatSwatch(swatch)
}

/**
 * Get the product swatches
 *
 * Changes the default based on the currently selected colors
 *
 * @param {array} productOptions - Swatches options
 * @param {string} defaultSwatch - Default from prismic
 * @param {number} swatchOffset - offset to randomize the swatch to select
 * @returns {{
 *  optionsList: { value: string, label: string, background: string, useInverseCheckColor: boolean }[],
 *  size: 'small' | 'medium',
 *  initialSelection: string
 * }}
*/
export const getSwatches = (productOptions, defaultSwatch, swatchOffset) => {
    const swatchOptions = productOptions.find((option) => ['fabric', 'material', 'color'].includes(option.code))

    if (!swatchOptions) {
        return null
    }

    const optionsList = swatchOptions.values.map(value => {
        return {
            value: formatSwatch(value.label),
            label: value.label,
            background: value.swatch,
            useInverseCheckColor: true
        }
    })

    const initialSwatch = getDefaultSwatch(swatchOptions.values, defaultSwatch, swatchOffset)

    return {
        optionsList,
        size: 'small',
        initialSelection: formatSwatch(initialSwatch)
    }
}

/**
 * Get the product images for category
 *
 * @param {object} product
 * @param {object?} imageProps
 * @param {boolean} hideSwatchesImages
 * @returns {{
 *  default: { folder: string, name: string, alt: string },
 *  hover: { folder: string, name: string, alt: string }
 * }}
*/
export const getImages = (product, imageProps, hideSwatchesImages = false) => {
    const productCode = product.productCode
    const title = product.title
    const category = product.category?.toLowerCase()

    return (swatch) => {
        const swatchModifier = hideSwatchesImages
            ? false
            : swatch && formatSwatch(swatch)

        const defaultImageName = swatchModifier ? `${productCode}-${swatchModifier}-plp.jpg` : `${productCode}-plp.jpg`
        const hoverImageName = swatchModifier ? `${productCode}-${swatchModifier}-plp-hover.jpg` : `${productCode}-plp-hover.jpg`
        const alt = `The ${title}`

        const defaultImage = {
            ...imageProps,
            alt,
            lazyLoadLabel: alt,
            folder: `category/${category}/${productCode}-plp`,
            name: defaultImageName,
            isUniversal: true
        }

        const hoverImage = {
            ...imageProps,
            alt,
            folder: `category/${category}/${productCode}-plp-hover`,
            name: hoverImageName,
            isUniversal: true
        }

        return {
            default: defaultImage,
            hover: hoverImage
        }
    }
}

const getArdadSelectedOptions = (
    attributeSets,
    defaultSelectedOptions,
    productOptions,
    swatch,
    hideSwatchesImages
) => {
    let selectedOptions = {}

    for (const attribute of attributeSets) {
        if (hideSwatchesImages || attribute !== 'color' || attribute !== 'fabric') {
            const attributeValue = defaultSelectedOptions?.[attribute]
                || productOptions?.find(option => option.code === attribute)?.default?.toLowerCase()
            selectedOptions = {
                ...selectedOptions,
                [attribute]: attributeValue
            }
        }
    }

    if (swatch && !hideSwatchesImages) {
        if (attributeSets.includes('color')) {
            selectedOptions.color = formatSwatch(swatch)
        }
        if (attributeSets.includes('fabric')) {
            selectedOptions.fabric = formatSwatch(swatch)
        }
    }

    return selectedOptions
}

/**
 * Get the product images from ARDAD
 *
 * @param {object} product
 * @param {string} slot
 * @param {object} imageProps
 * @param {boolean} hideSwatchesImages
 * @returns {{
 *  default: { folder: string, name: string, alt: string },
 *  hover: { folder: string, name: string, alt: string }
 * }}
*/
export const getArdadImages = (product, slot = slots.productTile, imageProps, hideSwatchesImages = false) => {
    const productCode = product.productCode
    const title = product.title

    const defaultDescriptors = without(
        product.ardadDescriptors?.find(ardad => ardad.slot === slot)?.descriptors || [],
        null, undefined, ''
    )
    const hoverDescriptors = without(
        product.ardadDescriptors?.find(ardad => ardad.slot === `${slot}-hover`)?.descriptors || [],
        null, undefined, ''
    )
    const productArdadConfig = ardadConfigs?.[productCode]
    const ardadConfig = productArdadConfig?.[slot]
    const attributeSets = ardadConfig?.attributeSets
    const attributeSetsHover = ardadConfig?.attributeSetsHover
    const defaultSelectedOptions = ardadConfig?.defaultSelectedOptions

    if (defaultDescriptors?.length && hoverDescriptors?.length && attributeSets && attributeSetsHover) {
        return (swatch) => {
            const selectedOptions = getArdadSelectedOptions(attributeSets, defaultSelectedOptions, product.options, swatch, hideSwatchesImages)
            const selectedOptionsHover = getArdadSelectedOptions(attributeSetsHover, defaultSelectedOptions, product.options, swatch, hideSwatchesImages)

            const ardadDefaultImage = generateAssetURLs(
                productCode,
                { imageDescriptors: defaultDescriptors, attributeSets },
                selectedOptions,
                '3-2',
                true
            )

            const ardadHoverImage = generateAssetURLs(
                productCode,
                { imageDescriptors: hoverDescriptors, attributeSets: attributeSetsHover },
                selectedOptionsHover,
                '3-2',
                true
            )

            const alt = `The ${title}`
            const prefixOverride = {
                mobile: 'none',
                tablet: 'none',
                desktop: 'none'
            }

            const defaultImage = {
                ...imageProps,
                folder: ardadDefaultImage[0]?.folder,
                name: ardadDefaultImage[0]?.filename,
                alt,
                prefixOverride
            }

            const hoverImage = {
                ...imageProps,
                folder: ardadHoverImage[0]?.folder,
                name: ardadHoverImage[0]?.filename,
                alt,
                prefixOverride
            }

            return {
                default: defaultImage,
                hover: hoverImage
            }

        }
    }
    // TODO: [SPIKE] ARDAD fallback - https://saatva.atlassian.net/browse/DIS-302
    handleWarningMessage(`[getArdadImages] - Using ARDAD fallback, descriptors or attribute sets not found for product code ${productCode}`)
    return getImages(product, imageProps, hideSwatchesImages)
}
