import productCodes from '@/temp-configs/product-codes'

export const slots = {
    productTile: 'product-tile',
}

export const ardadConfigs = {
    // MATTRESSES
    [productCodes.classic]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.saatvaRX]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.contour5]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.latexHybrid]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.solaire]: {
        [slots.productTile]: {
            attributeSets: ['mattressType'],
            attributeSetsHover: [],
            defaultSelectedOptions: {
                mattressType: 'upper-flex'
            }
        }
    },
    [productCodes.zenhaven]: {
        [slots.productTile]: {
            attributeSets: ['mattressType'],
            attributeSetsHover: [],
            defaultSelectedOptions: {
                mattressType: 'standard'
            }
        }
    },
    [productCodes.memoryFoamHybrid]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.hd]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.youth]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.bunkAndTrundle]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.crib]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.dogBed]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: ['color']
        }
    },
    // FURNITURE
    [productCodes.adjustableBasePlus]: {
        [slots.productTile]: {
            attributeSets: ['size'],
            attributeSetsHover: ['size'],
            defaultSelectedOptions: {
                size: 'split-king'
            }
        }
    },
    [productCodes.foundation]: {
        [slots.productTile]: {
            attributeSets: ['foundationHeight'],
            attributeSetsHover: ['mattressType', 'foundationHeight'],
            defaultSelectedOptions: {
                mattressType: 'standard'
            }
        }
    },
    [productCodes.valencia]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.minori]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.milan]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.ternaRug]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: []
        }
    },
    [productCodes.adjustableBase]: {
        [slots.productTile]: {
            attributeSets: ['size'],
            attributeSetsHover: []
        }
    },
    [productCodes.merano]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.amalfiPlatformBed]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.adraLeather]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.amalfi]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.lyon]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.kananRug]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: []
        }
    },
    [productCodes.comoSwivelChair]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.copenhagen]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.lyonWithStorage]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.anaisChair]: {
        [slots.productTile]: {
            attributeSets: ['chairOption', 'fabric'],
            attributeSetsHover: ['chairOption', 'fabric']
        }
    },
    [productCodes.cirrusRug]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: []
        }
    },
    [productCodes.cassis]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.santoriniPlatformBed]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.loganChair]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.siena]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.naviRug]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: []
        }
    },
    [productCodes.calais]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.crete]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.constanceTuftedOttoman]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.porto]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.halleStoragePlatformBed]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.myraRug]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: []
        }
    },
    [productCodes.arnoCushionedBench]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.brienneChannelOttoman]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.marseille]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.marbella]: {
        [slots.productTile]: {
            attributeSets: ['sidePanel', 'fabric'],
            attributeSetsHover: ['sidePanel', 'fabric']
        }
    },
    [productCodes.scentedCandles]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.adamasRug]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: []
        }
    },
    [productCodes.scentedVotives]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.minoriWithStorage]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.amalfiWithStorage]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.cassisWithStorage]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.halleWithStorage]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.santorini]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.rhoneStorageBench]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.lucerne]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.lenaCushionedBench]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.lenaCushionedBench]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.halle]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.rhodes]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    [productCodes.lunaSwivelChair]: {
        [slots.productTile]: {
            attributeSets: ['fabric'],
            attributeSetsHover: ['fabric']
        }
    },
    // BEDDING
    [productCodes.pillow]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.pad]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.mattressProtector]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.graphiteMemoryFoamTopper]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.percaleSheets]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.sheets]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.sateenPillowcase]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.foamPillow]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.cloudFoamPillow]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.downAlternativeComforter]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.organicQuiltedPillow]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.highDensityFoamTopper]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.downAlternativePillow]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.naturalLatexMattressTopper]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.organicQuiltedMattressTopper]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.silkEyeMask]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: []
        }
    },
    [productCodes.signatureSheets]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.microcoilMattressTopper]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.linenSheets]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.percalePillowcase]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.lightweightAlternativeComforter]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.essentialSheetSet]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.organicSateenDuvet]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.organicSateenDuvet]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.plushTowels]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: []
        }
    },
    [productCodes.weightedSilkEyeMask]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.heavyweightComforter]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.sweaterKnitBlanket]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.organicCottonChannelQuilt]: {
        [slots.productTile]: {
            attributeSets: ['quiltOption', 'color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.percaleDuvet]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.waffleKnitBlanket]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.flannelSheetSet]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.weightedBlanket]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.embroideredSateenDuvet]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.waffleTowels]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: []
        }
    },
    [productCodes.linenPillowcase]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.linenDuvetCoverSet]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.diamondKnitBlanket]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.waffleKnitDuvetCoverSet]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.featherbedMattressTopper]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.bandedPercaleSheetSet]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.bandedPercalePillowcasePair]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.organicVelvetQuilt]: {
        [slots.productTile]: {
            attributeSets: ['quiltOption', 'color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.herringboneKnitBlanket]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.bandedPercaleDuvetSet]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.aeroQuilt]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.embroideredSateenSheet]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.sateenCottonPillowcases]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.essentialPillowcases]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.flannelPillowcases]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.embroideredSateenPillowCasePair]: {
        [slots.productTile]: {
            attributeSets: ['color'],
            attributeSetsHover: ['color']
        }
    },
    // BUNDLES
    [productCodes.classicStarterBundle]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: []
        }
    },
    [productCodes.sateenSheetsLatexPillow]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.sateenSheetsLatexPillowPad]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: ['color']
        }
    },
    [productCodes.sateenSheetsMemoryFoamPillow]: {
        [slots.productTile]: {
            attributeSets: [],
            attributeSetsHover: ['color']
        }
    }
}
